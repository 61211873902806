// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide-video{
    width: 69.353125vw;
    height: 39.0625vw;
    background: #201e1e;
}

.rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide {
    margin-top: 50px;
}

.rojo-tu-size-guide-0-x-size-guide-img-size-guide {
    width: 95%;
}

/*--- Inicio estilos ---*/ 

@media only screen and (min-width: 1026px) {

    .rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide-video{
        margin-bottom: 50px;
    }

    .rojo-tu-size-guide-0-x-size-guide-size-guide-box-2 {
        margin-bottom: 10vw;
    }

}

@media only screen and (max-width: 1025px) {
    .rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide-video{
        margin-bottom: 50px;
    }
}

 /*--- Fin estilos ---*/ 

`, "",{"version":3,"sources":["webpack://./src/styles/rojo-tu-size-guide.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA,yBAAyB;;AAEzB;;IAEI;QACI,mBAAmB;IACvB;;IAEA;QACI,mBAAmB;IACvB;;AAEJ;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;CAEC,sBAAsB","sourcesContent":[".rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide-video{\n    width: 69.353125vw;\n    height: 39.0625vw;\n    background: #201e1e;\n}\n\n.rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide {\n    margin-top: 50px;\n}\n\n.rojo-tu-size-guide-0-x-size-guide-img-size-guide {\n    width: 95%;\n}\n\n/*--- Inicio estilos ---*/ \n\n@media only screen and (min-width: 1026px) {\n\n    .rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide-video{\n        margin-bottom: 50px;\n    }\n\n    .rojo-tu-size-guide-0-x-size-guide-size-guide-box-2 {\n        margin-bottom: 10vw;\n    }\n\n}\n\n@media only screen and (max-width: 1025px) {\n    .rojo-tu-size-guide-0-x-size-guide-size-guide-box-2-web-size-guide-video{\n        margin-bottom: 50px;\n    }\n}\n\n /*--- Fin estilos ---*/ \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
