// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rojo-tu-spinner-0-x-spinner-spinner{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 80px;
}


.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #4D4D4D;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #FF3D00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} `, "",{"version":3,"sources":["webpack://./src/styles/rojo-tu-spinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;;IAEnB,gBAAgB;IAChB,mBAAmB;AACvB;;;AAGA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,6BAA6B;EAC7B,mCAAmC;EACnC,sBAAsB;EACtB,sCAAsC;AACxC;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,8BAA8B;EAC9B,oCAAoC;EACpC,gDAAgD;AAClD;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".rojo-tu-spinner-0-x-spinner-spinner{\n    display: flex;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n\n    margin-top: 40px;\n    margin-bottom: 80px;\n}\n\n\n.loader {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  display: inline-block;\n  border-top: 4px solid #4D4D4D;\n  border-right: 4px solid transparent;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n}\n.loader::after {\n  content: '';  \n  box-sizing: border-box;\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  border-left: 4px solid #FF3D00;\n  border-bottom: 4px solid transparent;\n  animation: rotation 0.5s linear infinite reverse;\n}\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
