// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rojo-tu-3d-0-x-3d-3d {
    height: 100vh;
}

.rojo-tu-3d-0-x-3d-3d-container,
.rojo-tu-3d-0-x-3d-3d-box-2,
.rojo-tu-3d-0-x-3d-3d-box-2-web-3d {
    height: 100%;
}

.rojo-tu-3d-0-x-3d-3d-box-2-web-3d iframe {
    border: none;
}

.rojo-tu-3d-0-x-3d-3d-box-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.972763vw 0;
}

.rojo-tu-3d-0-x-3d-3d-box-1-gender{
    font-size: 1.167315vw;
    font-weight: 700;
    color: #E31A2A;
}

.rojo-tu-3d-0-x-3d-3d-box-1-gender:last-child{
    padding-left: 0.243191vw;
}`, "",{"version":3,"sources":["webpack://./src/styles/rojo-tu-3d.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;;;IAGI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".rojo-tu-3d-0-x-3d-3d {\n    height: 100vh;\n}\n\n.rojo-tu-3d-0-x-3d-3d-container,\n.rojo-tu-3d-0-x-3d-3d-box-2,\n.rojo-tu-3d-0-x-3d-3d-box-2-web-3d {\n    height: 100%;\n}\n\n.rojo-tu-3d-0-x-3d-3d-box-2-web-3d iframe {\n    border: none;\n}\n\n.rojo-tu-3d-0-x-3d-3d-box-1 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 0.972763vw 0;\n}\n\n.rojo-tu-3d-0-x-3d-3d-box-1-gender{\n    font-size: 1.167315vw;\n    font-weight: 700;\n    color: #E31A2A;\n}\n\n.rojo-tu-3d-0-x-3d-3d-box-1-gender:last-child{\n    padding-left: 0.243191vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
