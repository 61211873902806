// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rojo-tu-home-0-x-home-baner-image {
    width: 100%;
}

.rojo-tu-home-0-x-home-baner-container{
    width: 90%;
    margin: 40px auto;
}

.rojo-tu-home-0-x-home-home-image-navigation{
    width: 100%;
    border-radius: 16px;
}

.rojo-tu-home-0-x-home-home-image-title{
    font-weight: 900;
    font-size: 20px;
    margin-top: 10px;
    display: block;
}

.rojo-tu-home-0-x-home-home-image-paragraph{
    font-weight: 400;
    font-size: 16px;
    
}

.rojo-tu-home-0-x-home-home-image-link{
    font-weight: 900;
    color: red;
}


/*--- Inicio estilos ---*/ 

@media only screen and (min-width: 1026px) {

    .rojo-tu-home-0-x-home-home-split-navigation-container{
        display: flex;
        width: 91.5%;
        margin: 0 auto;
        margin-bottom: 45px;
    }

    .rojo-tu-home-0-x-home-home-split-navigation{
        width: 33.33%;
        padding: 10px;
    }

}

@media only screen and (max-width: 1025px) {
    .rojo-tu-home-0-x-home-home-split-navigation-container{
        width: 91.5%;
        margin: 0 auto;
        margin-bottom: 45px;
    }

    .rojo-tu-home-0-x-home-home-split-navigation{
        margin-bottom: 60px;
    }
}

 /*--- Fin estilos ---*/ 

`, "",{"version":3,"sources":["webpack://./src/styles/rojo-tu-home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;;AAEnB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;;AAGA,yBAAyB;;AAEzB;;IAEI;QACI,aAAa;QACb,YAAY;QACZ,cAAc;QACd,mBAAmB;IACvB;;IAEA;QACI,aAAa;QACb,aAAa;IACjB;;AAEJ;;AAEA;IACI;QACI,YAAY;QACZ,cAAc;QACd,mBAAmB;IACvB;;IAEA;QACI,mBAAmB;IACvB;AACJ;;CAEC,sBAAsB","sourcesContent":[".rojo-tu-home-0-x-home-baner-image {\n    width: 100%;\n}\n\n.rojo-tu-home-0-x-home-baner-container{\n    width: 90%;\n    margin: 40px auto;\n}\n\n.rojo-tu-home-0-x-home-home-image-navigation{\n    width: 100%;\n    border-radius: 16px;\n}\n\n.rojo-tu-home-0-x-home-home-image-title{\n    font-weight: 900;\n    font-size: 20px;\n    margin-top: 10px;\n    display: block;\n}\n\n.rojo-tu-home-0-x-home-home-image-paragraph{\n    font-weight: 400;\n    font-size: 16px;\n    \n}\n\n.rojo-tu-home-0-x-home-home-image-link{\n    font-weight: 900;\n    color: red;\n}\n\n\n/*--- Inicio estilos ---*/ \n\n@media only screen and (min-width: 1026px) {\n\n    .rojo-tu-home-0-x-home-home-split-navigation-container{\n        display: flex;\n        width: 91.5%;\n        margin: 0 auto;\n        margin-bottom: 45px;\n    }\n\n    .rojo-tu-home-0-x-home-home-split-navigation{\n        width: 33.33%;\n        padding: 10px;\n    }\n\n}\n\n@media only screen and (max-width: 1025px) {\n    .rojo-tu-home-0-x-home-home-split-navigation-container{\n        width: 91.5%;\n        margin: 0 auto;\n        margin-bottom: 45px;\n    }\n\n    .rojo-tu-home-0-x-home-home-split-navigation{\n        margin-bottom: 60px;\n    }\n}\n\n /*--- Fin estilos ---*/ \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
