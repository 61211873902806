// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rojo-tu-countdown-0-x-countdown-countdown-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.rojo-tu-countdown-0-x-countdown-countdown-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #4d4d4d0d;
    justify-content: center;
    border-radius: 200px;
    box-shadow: rgb(0 0 0 / 13%) 0px 4px 12px;
}

.rojo-tu-countdown-0-x-countdown-countdown-box-text {
    font-weight: 700;
}

.rojo-tu-countdown-0-x-countdown-countdown-box-time {
    color: #E31A2A;
    font-weight: 700;
}


/*--- Inicio estilos ---*/

@media only screen and (min-width: 1026px) {

    .rojo-tu-countdown-0-x-countdown-countdown-box-text,
    .rojo-tu-countdown-0-x-countdown-countdown-box-time {
        font-size: 1.167315vw;
    }

    .rojo-tu-countdown-0-x-countdown-countdown-box {
        width: 5.836576vw;
        height: 5.836576vw;
        margin: 0 0.486381vw;
    }
}

@media only screen and (max-width: 1025px) {

    .rojo-tu-countdown-0-x-countdown-countdown-box-text,
    .rojo-tu-countdown-0-x-countdown-countdown-box-time {
        font-size: 16px;
    }

    .rojo-tu-countdown-0-x-countdown-countdown-box {
        width: 80px;
        height: 80px;
        margin: 0 10px;
    }
}

/*--- Fin estilos ---*/`, "",{"version":3,"sources":["webpack://./src/styles/rojo-tu-countdown.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;IACvB,oBAAoB;IACpB,yCAAyC;AAC7C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;;AAGA,yBAAyB;;AAEzB;;IAEI;;QAEI,qBAAqB;IACzB;;IAEA;QACI,iBAAiB;QACjB,kBAAkB;QAClB,oBAAoB;IACxB;AACJ;;AAEA;;IAEI;;QAEI,eAAe;IACnB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,cAAc;IAClB;AACJ;;AAEA,sBAAsB","sourcesContent":[".rojo-tu-countdown-0-x-countdown-countdown-container {\n    display: flex;\n    justify-content: center;\n    margin-top: 60px;\n}\n\n.rojo-tu-countdown-0-x-countdown-countdown-box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background: #4d4d4d0d;\n    justify-content: center;\n    border-radius: 200px;\n    box-shadow: rgb(0 0 0 / 13%) 0px 4px 12px;\n}\n\n.rojo-tu-countdown-0-x-countdown-countdown-box-text {\n    font-weight: 700;\n}\n\n.rojo-tu-countdown-0-x-countdown-countdown-box-time {\n    color: #E31A2A;\n    font-weight: 700;\n}\n\n\n/*--- Inicio estilos ---*/\n\n@media only screen and (min-width: 1026px) {\n\n    .rojo-tu-countdown-0-x-countdown-countdown-box-text,\n    .rojo-tu-countdown-0-x-countdown-countdown-box-time {\n        font-size: 1.167315vw;\n    }\n\n    .rojo-tu-countdown-0-x-countdown-countdown-box {\n        width: 5.836576vw;\n        height: 5.836576vw;\n        margin: 0 0.486381vw;\n    }\n}\n\n@media only screen and (max-width: 1025px) {\n\n    .rojo-tu-countdown-0-x-countdown-countdown-box-text,\n    .rojo-tu-countdown-0-x-countdown-countdown-box-time {\n        font-size: 16px;\n    }\n\n    .rojo-tu-countdown-0-x-countdown-countdown-box {\n        width: 80px;\n        height: 80px;\n        margin: 0 10px;\n    }\n}\n\n/*--- Fin estilos ---*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
